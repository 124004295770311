import * as React from 'react';
import MD from 'markdown-to-jsx';
import b_ from 'b_';
import cn from 'classnames';
import { Text } from '../Text';
import './style.scss';

const b = b_.lock('Markdown');

const components = {
  h1: props => <Text type='h1' {...props} />,
  // a: ({ children, ...props }) => <Link {...props} target='_blank'>{children}</Link>,
  blockquote: ({ children, ...props }) => <blockquote {...props} className={b('blockquote')}>{children}</blockquote>,
  // eslint-disable-next-line jsx-a11y/alt-text
  img: props => <img {...props} className={b('img')} />
};

export const Markdown = ({
  bedsheet,
  className,
  overrides,
  text
}) => {
  const options = { overrides: { ...components, ...overrides }, forceBlock: true };

  return (
    <MD className={cn(b({ bedsheet }), className)} options={options}>{text}</MD>
  );
};
