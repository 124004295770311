import React from 'react';
import Helmet from 'react-helmet';
import { Markdown } from '../components/Markdown';

const data = `
# Такой страницы не существует

Перейти в [каталог товаров](/catalog).
`;

const NotFoundRoute = () => (
  <>
    <Helmet title='Ошибка 404' />
    <Markdown text={data} bedsheet />
  </>
);

export default NotFoundRoute;
